import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

export default function withLazyLoading({ defaultLazy = false } = {}) {
  return WrappedComponent => {
    function LazyLoadedComponent({
      lazy,
      lazyBuffer,
      lazyContainerRef,
      lazyTriggerOnce,
      ...props
    }) {
      let [ref, inView] = useInView({
        triggerOnce: lazyTriggerOnce,
        rootMargin: `${lazyBuffer}px`,
        root: lazyContainerRef
      });

      if (!lazy || CONFIG.isTest || window.__DPL_BOT_UA) {
        ref = null;
        inView = true;
      }

      return (
        <WrappedComponent {...props} isInViewport={inView} setLazyRef={ref} />
      );
    }

    LazyLoadedComponent.propTypes = {
      /**
       * Load component only when it enters the viewport
       */
      lazy: PropTypes.bool,
      /**
       * Optional when props.lazy is true. Useful for preloading an image before
       * it enters the viewport by <value> pixels
       */
      lazyBuffer: PropTypes.number,
      /**
       * Necessary for lazyBuffer to work if the scrollable container isn't
       * window
       */
      // eslint-disable-next-line react/forbid-prop-types
      lazyContainerRef: PropTypes.any,
      /**
       * when set to false, component will get unmounted when it leaves the view
       */
      lazyTriggerOnce: PropTypes.bool
    };

    LazyLoadedComponent.defaultProps = {
      lazy: defaultLazy,
      lazyBuffer: 0,
      lazyContainerRef: null,
      lazyTriggerOnce: true
    };

    LazyLoadedComponent.WrappedComponent = WrappedComponent;

    return LazyLoadedComponent;
  };
}
