import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { addQueryToUrl, removeFromUrl } from 'dpl/util/queryString';
import { preloadImage, isImageLoaded, difference } from 'dpl/shared/utils';
import withLazyLoading from 'dpl/decorators/withLazyLoading';

const IS_BOT_UA = window.__DPL_BOT_UA;

function getSizeParam(element, width, height) {
  const devicePixelRatio = window.devicePixelRatio || 1;

  let finalWidth = parseInt(width, 10);
  let finalHeight = parseInt(height, 10);

  if (element && !(finalWidth && finalHeight)) {
    const rect = element.getBoundingClientRect();
    finalWidth = rect.width;
    finalHeight = rect.height;
  }

  finalWidth = Math.ceil(finalWidth * devicePixelRatio);
  finalHeight = Math.ceil(finalHeight * devicePixelRatio);

  return `${finalWidth}x${finalHeight || finalWidth}`;
}

function getScrappyUrl(url, width, height, crop, element) {
  const isManuallyCropped = url.includes('manual');

  if (!isManuallyCropped) {
    url = removeFromUrl(['resize', 'crop'], url);
  }

  return addQueryToUrl(
    { [crop ? 'crop' : 'resize']: getSizeParam(element, width, height) },
    url
  );
}

const SmartImage = React.memo(
  ({
    Tag = 'img',
    setLazyRef = null,
    className = '',
    src = null,
    loadingClass = '',
    minWidth = null,
    minHeight = null,
    width = null,
    height = null,
    alt = '',
    crop = false,
    forceOriginalSrc = false,
    wrapperOnlyClassName = '',
    imageOnlyClassName = '',
    onLoad = null,
    allowContextMenu = false,
    isInViewport
  }) => {
    const ref = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState(null);
    const willUnmountRef = useRef(false);

    if (IS_BOT_UA) {
      Tag = 'img';
    }

    useEffect(() => {
      if (!src || !isInViewport) return;

      let newSrc = src;

      if (!forceOriginalSrc && !/^(blob|data):/.test(src)) {
        newSrc = getScrappyUrl(src, width, height, crop, ref.current);
      }

      if (newSrc === imageSrc) return;

      setImageSrc(newSrc);
      setIsLoading(!isImageLoaded(newSrc));

      preloadImage(newSrc)
        // eslint-disable-next-line no-console
        .catch(() => console.error(`Failed to preload image ${newSrc}`))
        .then(target => {
          if (!willUnmountRef.current && target) {
            setIsLoading(false);
            onLoad?.(target);
          }
        });
    }, [src, isInViewport, forceOriginalSrc, crop, width, height, ref.current]);

    useEffect(
      () => () => {
        willUnmountRef.current = true;
      },
      []
    );

    const dimensionStyles = useMemo(
      () => ({
        width,
        height,
        minWidth,
        minHeight
      }),
      [width, height, minWidth, minHeight]
    );

    const elementProps = useMemo(() => {
      if (Tag === 'img') {
        return {
          alt,
          src: imageSrc,
          style: {
            ...dimensionStyles,
            userDrag: 'none',
            WebkitUserDrag: 'none',
            userSelect: 'none'
          }
        };
      }

      return {
        role: 'img',
        title: alt,
        'aria-label': alt,
        style: {
          ...dimensionStyles,
          backgroundImage: imageSrc && `url('${imageSrc}')`
        }
      };
    }, [Tag, alt, imageSrc, dimensionStyles]);

    const handleContextMenu = useCallback(
      e => {
        if (!allowContextMenu) {
          e.preventDefault();
        }
      },
      [allowContextMenu]
    );

    const setRefCallback = useCallback(
      el => {
        ref.current = el;
        setLazyRef?.(el);
      },
      [setLazyRef]
    );

    const outerClassName = useMemo(() => {
      if (!imageOnlyClassName) return className;
      return difference(
        className.split(' '),
        imageOnlyClassName.split(' ')
      ).join(' ');
    }, [className, imageOnlyClassName]);

    return (
      <div
        data-test-id="smart-image-wrapper"
        className={classnames(
          'SmartImage f0 dib',
          outerClassName,
          wrapperOnlyClassName,
          { [loadingClass]: isLoading }
        )}
        style={dimensionStyles}
        ref={setRefCallback}
      >
        {imageSrc && (
          <Tag
            {...elementProps}
            className={classnames('SmartImage__image', className, {
              'o-0': !IS_BOT_UA && isLoading
            })}
            onContextMenu={handleContextMenu}
          />
        )}
      </div>
    );
  }
);

SmartImage.displayName = 'SmartImage';

SmartImage.propTypes = {
  Tag: PropTypes.oneOf(['img', 'div']),
  className: PropTypes.string,
  src: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  /**
   * className to be used while image is loading
   */
  loadingClass: PropTypes.string,
  /**
   * When `true`, add crop params to the dimensions given
   */
  crop: PropTypes.bool,
  /**
   * When `true`, use props.src as-is and not crop/resize
   */
  forceOriginalSrc: PropTypes.bool,
  wrapperOnlyClassName: PropTypes.string,
  imageOnlyClassName: PropTypes.string,
  onLoad: PropTypes.func,
  isInViewport: PropTypes.bool.isRequired,
  setLazyRef: PropTypes.func,
  allowContextMenu: PropTypes.bool
};

SmartImage.defaultProps = {
  Tag: 'img',
  setLazyRef: null,
  className: '',
  src: null,
  loadingClass: '',
  minWidth: null,
  minHeight: null,
  width: null,
  height: null,
  alt: '',
  crop: false,
  forceOriginalSrc: false,
  wrapperOnlyClassName: '',
  imageOnlyClassName: '',
  onLoad: null,
  allowContextMenu: false
};

export default withLazyLoading()(SmartImage);
