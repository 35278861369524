import React from 'react';
import PropTypes from 'prop-types';

import { makeSequence } from 'dpl/shared/utils';

export default function Stars({
  backgroundColor,
  fillColor,
  fullCount,
  id,
  starWidth,
  totalCount
}) {
  const defId = [
    'Stars__star',
    id,
    fullCount,
    starWidth,
    totalCount,
    backgroundColor,
    fillColor
  ].join('|');

  return (
    <div className="Stars">
      <div className="flex nowrap f0">
        {makeSequence(totalCount).map(idx => {
          const starDefId = `${defId}-${idx}`;

          return (
            <span key={idx} className="Stars__star">
              <svg viewBox="0 0 16 16" width={starWidth}>
                <defs>
                  <linearGradient
                    id={starDefId}
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset={Math.min(1, fullCount - idx)}
                      style={{ stopColor: fillColor }}
                    />
                    <stop offset="0" style={{ stopColor: backgroundColor }} />
                  </linearGradient>
                </defs>
                <path
                  d="M10.472 5.01L16 5.811l-4 3.897.945 5.508L8 12.616l-4.944 2.6L4 9.708 0 5.811l5.528-.8L8 0z"
                  fill={`url(#${starDefId})`}
                />
              </svg>
            </span>
          );
        })}
      </div>
    </div>
  );
}

Stars.propTypes = {
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
  fullCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  starWidth: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};

Stars.defaultProps = {
  backgroundColor: '#E6E6EB',
  fillColor: '#E54563'
};
